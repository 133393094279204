.footer__area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 87px;
  height: 100%;
  width: 100%;
  background-color: rgb(189, 30, 56, 0.9);
  color: white;
  -moz-box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
}

.footer__area-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer__area-content-title {
  font-weight: 900;
}

.footer__area-content-phones {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer__area-content-dash {
  margin: 0 10px;
}

.nap_container {
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 1280px) {
  .footer__area-content-phones {
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .footer__area-content-phones {
    min-width: 220px;
  }
}

@media screen and (max-width: 860px) {
  .footer__area-content {
    display: flex;
    flex-direction: column;
  }
  .footer__area-content-dash {
    display: none;
  }

  .footer__area-content-title {
    font-weight: 900;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 660px) {
}

@media screen and (max-width: 480px) {
  .footer__area-content-title {
    font-weight: 900;
  }
}
