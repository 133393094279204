.reviews__area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 2%;
}

.reviews__area-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.reviews__area-content-title {
  text-align: center;
}

.reviews__review-btn-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px 0 5px 0;
}

.reviews__btn-div {
  margin: 0 20px;
}

.reviews__btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 40px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  color: rgb(189, 30, 56);
  border: 2px solid rgb(189, 30, 56);
  -moz-box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.3);
  box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.3);
}

.reviews__btn:hover {
  background-color: rgb(189, 30, 56);
  color: white;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 810px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 715px) {
}

@media screen and (max-width: 660px) {
}

@media screen and (max-width: 480px) {
  .reviews__area {
    margin-bottom: 4%;
  }
  .reviews__area-content-title {
    max-width: 400px;
  }
  .reviews__btn {
    max-width: 180px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 400px) {
  .reviews__btn {
    margin: 0 -8px;
    width: 170px;
    padding: 4px 20px;
  }
  .reviews__area-content-title {
    max-width: 360px;
  }
}

@media screen and (max-width: 360px) {
  .reviews__btn {
    margin: 0 -12px;
    width: 155px;
    padding: 4px 20px;
  }
  .reviews__area-content-title {
    max-width: 350px;
    font-size: 0.8rem;
  }
}
