.contactUs__area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.6);
  margin-bottom: 2%;
}

.contactUs__area_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: white;
}

.contactUs__title {
  font-weight: 900;
  margin: 20px 0 0px 0;
}

.contactUs__address-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
}

.address_div {
  font-style: normal;
}

.contactUs__map {
  margin-top: 8px;
  border: 1px solid rgb(139, 139, 139);
}

#mapI_frame_id {
  border: none;
  width: 600;
  height: 380;
}

.contactUs__address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 30px 60px;
}

.contactUs__address-p2 {
  display: flex;
  flex-direction: row;
}

.contactUs__address-city,
.contactUs__address-province {
  white-space: pre-wrap;
}

.contactUs__contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contactUs__contacts-phones_div,
.contactUs__contacts-email_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px 80px 0 80px;
}

.contactUs__contacts-phones_iconArea,
.contactUs__contacts-email_iconArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 50px;
  margin-right: 5px;
}

.contactUs__contacts-email_div {
  align-items: flex-start;
}

#email_icon {
  margin-top: 4px;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 810px) {
  .contactUs__title {
    margin: 3% 0;
  }
  .contactUs__address-area {
    flex-direction: column;
    justify-content: center;
  }
  .contactUs__address {
    margin: 1% 0;
  }

  .contactUs__contacts-phones_div,
  .contactUs__contacts-email_div {
    margin: 3% 0;
  }

  .contactUs__contacts {
    flex-direction: column;
    margin: 2% 0 0 0;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 715px) {
  #mapI_frame_id {
    width: 550px;
  }
}

@media screen and (max-width: 660px) {
  #mapI_frame_id {
    width: 500px;
  }
}

@media screen and (max-width: 480px) {
  #mapI_frame_id {
    width: 400px;
  }
}

@media screen and (max-width: 360px) {
  #mapI_frame_id {
    width: 340px;
  }
}
