.component__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  height: 100%;
  width: 100%;
}

.name_div {
  position: absolute;
  z-index: -10;
}

.map_div {
  opacity: 0;
  visibility: hidden;
}

#logo_id {
  width: 276px;
  height: 106px;
  margin: 30px 0 30px 0;
}

.component__main-areas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1100px;
  font-weight: 900;
  margin-bottom: 20px;
}

.component__main-areas-div,
.component__main-businessHours,
.component__main-languagesArea,
.component__main-promotionsArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component__main-businessHours {
  margin: 0 5px;
}

.component__main-businessHours_div {
  padding: 0 4px;
}

.component__main-areas-dash {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
}

#component__main-areas-dash_id1 {
  display: none;
}

.component__main-businessHours,
.component__main-languagesArea,
.component__main-promotionsArea {
  margin: 0 0 20px 0;
}

.component__main-businessHours-title,
.component__main-languagesArea-title {
  font-weight: 900;
  margin-right: 8px;
}

.component__main-promotionsArea {
  flex-direction: column;
}

@media screen and (max-width: 1280px) {
  .component__main-languagesArea {
    flex-direction: column;
    text-align: center;
    padding: 0 3%;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 810px) {
  .component__main-areas {
    flex-direction: inline-flex;
  }
  .component__main-areas-dash {
    display: none;
  }
  .component__main-areas-div {
    margin: 0 1%;
  }
}

@media screen and (max-width: 768px) {
  .component__main-businessHours {
    flex-direction: column;
    text-align: center;
  }
  .component__main-areas-div {
    margin: 0 0.5%;
  }
}

@media screen and (max-width: 690px) {
  .component__main-areas {
    flex-direction: column;
  }
}

@media screen and (max-width: 660px) {
  .component__main-promotions {
    padding: 0 3%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .component__main-promotions {
    padding: 0 3%;
    text-align: center;
  }
}
