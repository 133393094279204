.app__navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(189, 30, 56);
  color: white;
  font-weight: 900;
  -moz-box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 3px rgb(0, 0, 0, 0.5);
}

.app__navbar-options {
  display: flex;
  flex-direction: row;
}

.app__navbar-options li {
  margin-left: 10px;
  list-style-type: none;
}

.app__navbar-smallscreen {
  margin-right: 1%;
  display: none;
}

.links_Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navBarLinkStyle {
  color: white;
  margin: 0 15px;
  text-transform: uppercase;
  text-decoration: none;
}

.navBarLinkStyle:hover {
  text-decoration: underline;
}

.header__call,
.header__call-button {
  color: white;
  font-weight: 900;
  margin: 2px 1% 0 0;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid rgb(189, 30, 56);
}

.header__call-button:hover {
  border-bottom: 2px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .app__navbar {
    min-height: 90px;
  }

  .app__navbar-options {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .app__navbar {
    min-height: 90px;
    flex-direction: column;
  }

  .app__navbar-options {
    flex-direction: column;
  }
  .header__call-li {
    position: relative;
    left: 20px;
  }
  .header__call-text {
    position: relative;
    left: -20px;
  }

  .links_Container {
    margin: 5px 0;
    padding: 10px 0;
    background-color: white;
    width: 100%;
  }
  .navBarLinkStyle {
    color: rgb(189, 30, 56);
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 360px) {
  .app__navbar-options {
    flex-direction: column;
  }

  .app__navbar {
    flex-direction: column;
  }

  .header__call-button {
  }
}

@media screen and (max-width: 360px) {
  .header__call-li {
    left: 0px;
    font-size: 20px;
  }
  .header__call-text {
    left: 0px;
    font-size: 20px;
  }

  .header__call-button {
    font-size: 20px;
    margin: 5% 0 1% 0;
  }
}
