.component__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  height: 100%;
  width: 100%;
}

#logo2_id {
  width: 276px;
  height: 106px;
  margin: 30px 0 30px 0;
}
