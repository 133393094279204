.carImage_div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
}

#carImage_id {
	width: 100%;
	height: 100%;
}
