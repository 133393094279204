.pricesContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.priceSectionTitle {
  text-transform: uppercase;
}

.pricesPlaceholder {
  padding: 15px 30px;
}

.priceTextParagraph_p {
  padding: 5px 0;
  font-size: 20px;
}

.pricesListPlaceholder {
  width: 100%;
  background-color: rgb(189, 30, 56, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricesPlaceholder_prices {
  max-width: 1080px;
  width: 95%;
}

.priceElement_div {
  width: 95%;
  max-width: 1290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 5px 5px 5px 15px;
  -moz-box-shadow: 3px 3px 6px 2px rgb(0, 0, 0, 0.5);
  -webkit-box-shadow: 3px 3px 6px 2px rgb(0, 0, 0, 0.5);
  box-shadow: 3px 3px 6px 2px rgb(0, 0, 0, 0.5);
  border: 3px solid gray;
  border-radius: 10px;
  background-color: white;
}

.priceElementTitlePrice {
  width: 100%;
  display: inherit;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.priceElementTitle {
  padding-right: 10px;
}

.priceElementPrice {
  width: 120px;
  height: 100%;
  display: inherit;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.priceElementPrice div {
  height: 100%;
  display: inherit;
  justify-content: flex-start;
}

.hourInfo {
  font-size: 18px;
  display: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.priceElementDescription div,
.pricesRental_div div {
  font-size: 20px;
}

.pricesRental_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 0px 0;
  padding: 0 30px;
  text-align: center;
}

.pricesPhones_div {
  display: flex;
  flex-direction: row;
}

.pricesPhones {
  margin: 10px 10px;
}

@media screen and (max-width: 1280px) {
  .component__main-languagesArea {
    flex-direction: column;
    text-align: center;
    padding: 0 3%;
  }
}

@media screen and (max-width: 1034px) {
}

@media screen and (max-width: 820px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 660px) {
  .pricesPhones_div {
    flex-direction: column;
    margin-top: 10px;
  }

  .pricesPhones {
    margin: 3px 10px;
  }
}

@media screen and (max-width: 480px) {
  .pricesPlaceholder_prices {
    width: 85%;
  }
}
