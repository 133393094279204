@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-family: "Inter", sans-serif;
}

.app__main_div {
  background-color: white;
}

.bold {
  font-weight: bold;
}

.corporate_red {
  color: rgb(189, 30, 56);
}
