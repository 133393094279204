.services__area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1% 0;
  background-color: rgb(189, 30, 56, 0.9);
  color: white;
  -moz-box-shadow: 0 5px 5px 3px rgb(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 5px 5px 3px rgb(0, 0, 0, 0.5);
  box-shadow: 0 5px 5px 3px rgb(0, 0, 0, 0.5);
}

.services__title {
  font-weight: 900;
  margin: 0.5% 0;
}

.services__option {
  margin-top: 0.5%;
}

@media screen and (max-width: 1280px) {
  .services__title {
    margin: 0.9% 0;
  }
  .services__ul {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .services__title {
    margin: 1.3% 0;
  }
}

@media screen and (max-width: 810px) {
  .services__title {
    margin: 1.7% 0;
  }
  .services__ul {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .services__title {
    margin: 2% 0;
  }
}

@media screen and (max-width: 660px) {
  .services__ul {
    width: 85%;
  }
  .services__option {
    margin-top: 1%;
  }
}

@media screen and (max-width: 480px) {
  .services__ul {
    width: 82%;
  }
}
